import { VEHICLE_LIST_FILTER_NAME } from 'Components/VehicleList/VehicleListFilters.consts';
import { VEHICLE_DETAILS_IMAGE_SECTIONS } from 'Services/analytics/events/galleryEvents/galleryEvents.consts';
import { VehicleDetailsImageSectionsKeys } from 'Services/analytics/events/galleryEvents/galleryEvents.types';

import { AssociateType } from './types/kyc.types';
import ROUTES from './routes';
import { AllPageSlugs, AuthenticatedPageSlugs } from './routes.types';

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export const DEALER_PERMISSIONS = Object.freeze({
	KYC: 'kyc',
});
export const DEALER_STATUS = {
	APPROVED_PREVIEW: 'approved_preview',
} as const;

export const AUTHENTICATED_PAGES_SLUGS = Object.entries(ROUTES).reduce((routes, [key, value]) => {
	if (!value?.isProtected) {
		return routes;
	}

	return { ...routes, [key]: value?.href };
}, {} as AuthenticatedPageSlugs);

export const PAGE_SLUGS = Object.entries(ROUTES).reduce((routes, [key, value]) => {
	const hrefWithoutSlashPrefix = value?.href?.substring(1);
	return { ...routes, [key]: hrefWithoutSlashPrefix };
}, {} as AllPageSlugs);

export const NO_SCROLL_TO_TOP_ROUTES = [ROUTES.VEHICLE_LIST.href];

export const VEHICLE_FUEL_TYPES = Object.freeze({
	DIESEL: 'Diesel',
	ELECTRIC: 'Electric',
	GAS_BI_FUEL: 'Gas bi-fuel',
	HYBRID: 'Hybrid',
	PETROL: 'Petrol',
});

export const VEHICLE_BATTERY_OWNERSHIP = Object.freeze({
	LEASED: 'Leased',
	OWNED: 'Owned',
});

export const VEHICLE_CHARGING_CABLES = Object.freeze({
	FAST_CHARGING: 'Fast charging',
	THREE_PIN_DOMESTIC: '3-pin domestic',
});

export const TRANSPORT_MESSAGE_CODES = Object.freeze({
	INCORRECT_DEALER: 'motorway.enquiries.closing_bid.incorrect_dealer',
	NO_TRANSPORT_ENTRY: 'motorway.enquiries.no_transport_entry',
	NO_TRANSPORT_TYPE_SET: 'motorway.enquiries.no_transport_type_set',
	TRANSPORT_CANCELLED: 'motorway.enquiries.transport_entry_cancelled',
});

export const APP_THEME = 'dark';

export const ERRORS = {
	ABORT_ROUTE_CHANGE: 'Abort route change',
	UNABLE_TO_FETCH_INITIAL_MAKES: 'Unable to fetch makes on initial page load',
	UNABLE_TO_FETCH_INITIAL_MODELS: 'Unable to fetch models on initial page load',
	UNABLE_TO_FETCH_INITIAL_SAVED_FILTERS: 'Could not get saved searches on initial page load',
	UNABLE_TO_FETCH_SALE_TIME: 'Unable to fetch sale time on initial page load',
};

export const ENVIRONMENTS = {
	DEVELOPMENT: 'development',
	PRODUCTION: 'production',
	STAGING: 'staging',
	TEST: 'test',
};

export const TEAM = Object.freeze({
	DEALER_SQUAD: 'DEALER_SQUAD',
	PAYMENTS_FE: 'payments-fe',
	THUNDERBIRDS_FE: 'thunderbirds-fe',
});

export const INPUT_DEBOUNCE_TIME = 500; // milliseconds

export const FORM_TYPES = {
	CREATE: 'create',
	UPDATE: 'update',
};

export const DEFAULT_LANGUAGE = 'en-GB';

// Consider migrating to as const with - DEP-56
export const DEALER_FROZEN_STATUSES = new Set([
	'frozen',
	'frozen_enforcement',
	'frozen_debt',
	'approved_preview',
	'frozen_preview',
]);

export const DEALER_BLOCKED_STATUS = 'blocked_pending';

export const PAYMENT_MFA_ELEMENT_ID = 'payments-MFA-portal';

export const MAXIMUM_PURCHASE_VALUE_LENGTH = 9; // 7 digits and 2 commas

export const TIME_IN_MS = {
	FIFTEEN_MINUTES: 900_000,
	TEN_SECONDS: 10_000,
	THIRTEEN_HOURS: 46_800_000,
	THIRTY_MINUTES: 1_800_000,
};

export const LIST_TYPES = {
	auction: 'auction',
	buyItNow: 'buyItNow',
	shortlist: 'shortlist',
} as const;

export const COOKIE_NAME = {
	mockedDateTime: 'mockedDateTime',
};

export const EN_DASH = '–'; // longer dash than a hyphen

export const DEFAULT_LIST_TYPE_PARAM = `${VEHICLE_LIST_FILTER_NAME.LIST_TYPE}=${LIST_TYPES.auction}`;
// This route is used in multiple places, when changing it, make sure that the links are not broken and include ?
export const DEFAULT_VEHICLE_LIST_ROUTE = {
	as: `${ROUTES.VEHICLE_LIST.as()}?${DEFAULT_LIST_TYPE_PARAM}`,
	href: `${ROUTES.VEHICLE_LIST.href}?${DEFAULT_LIST_TYPE_PARAM}`,
};

export const VEHICLE_DETAILS_IMAGE_SECTIONS_NAMES = (
	Object.keys(VEHICLE_DETAILS_IMAGE_SECTIONS) as VehicleDetailsImageSectionsKeys[]
).reduce(
	(acc, cur) => {
		acc[cur] = cur;
		return acc;
	},
	{} as Record<VehicleDetailsImageSectionsKeys, VehicleDetailsImageSectionsKeys>,
);

export const DEFAULT_KYC_COMPANY_DETAILS = {
	associates: [
		{ fullName: '', isPrimaryContact: true, type: AssociateType.accountManager },
		{ fullName: '', isPrimaryContact: true, type: AssociateType.director },
		{ fullName: '', isPrimaryContact: true, type: AssociateType.shareholder },
	],
	companyRegistrationNumber: '',
	legalName: '',
	registeredAddress: {},
	tradingAddress: {},
	tradingName: '',
	type: '',
};
